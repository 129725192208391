<template>
    <div class="task__container">
        <div class="task__main--grid">
            <el-card class="custom__card--auto" shadow="always">
                <li>
                    <p id="taskName" class="item__title--pointer">任务标题</p>
                    <el-input size="small" v-model="taskObj.taskName" placeholder="请输入任务标题" @focus="connectTarget"
                        clearable></el-input>
                </li>
                <li>
                    <p id="taskDescription" class="item__title--pointer">任务描述</p>
                    <el-input size="small" type="textarea" v-model="taskObj.taskDescription" :rows="5"
                        placeholder="请输入任务描述" maxlength="200" show-word-limit @focus="connectTarget"
                        clearable></el-input>
                </li>
                <li>
                    <p id="money" class="item__title--pointer">任务积分(0-5)</p>
                    <el-input-number @focus="connectTarget" v-model="taskObj.money" :min="0" :max="5" size="small"
                        :step="1" step-strictly>
                    </el-input-number>
                    <el-popover placement="right" title="积分规则" width="300" trigger="hover">
                        <p>0积分：顺手完成的任务</p>
                        <p>1积分：需要一定时间完成的任务</p>
                        <p>2积分：需要募集材料、花费精力完成的任务</p>
                        <p>3积分：需要借助其他力量才能够完成的任务</p>
                        <p>4积分：需要统领赛事组，引领整个赛事的走向，花费一定时间和精力才能完成。</p>
                        <p>5积分：能为ASG赛事团队带来荣誉、金钱以及赞助的任务。</p>
                        <p slot="reference" class="warning-text">请按照积分规则给予一定积分<i class="el-icon-question"></i></p>
                    </el-popover>
                </li>
                <li>
                    <el-button size="small" icon="el-icon-document-checked" type="primary"
                        @click="addTask">新建任务</el-button>
                </li>
            </el-card>
            <div class="task__code--highlight">
                <p class="open__close--tag">{</p>
                <p class="ele__start"><span>"chinaname":</span><span class="varable--orange">"{{
                    user.label }}"</span></p>
                <p class="ele__start"><span id="taskName-1">"taskName":</span><span class="varable--orange">"{{
                    taskObj.taskName }}"</span></p>
                <p class="ele__start"><span id="taskDescription-1">"taskDescription":</span><span
                        class="varable--orange">"{{ taskObj.taskDescription
                        }}"</span></p>
                <p class="ele__start"><span id="money-1">"money":</span><span class="varable--orange">{{ taskObj.money
                        }}</span></p>
                <p class="open__close--tag">}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { setTask } from '@/api/admin/index.js';
export default {
    name: 'SettingTask',
    props: {
        user: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            taskObj: {
                taskName: '',
                taskDescription: '',
                money: 0
            },
            jsPlumb: null
        };
    },
    computed: {
        userName() {
            return this.$store.state?.userInfo?.chinaname || sessionStorage.getItem('chinaname') || '未知超管';
        },
        userId() {
            return this.$store.state?.userInfo?.id || sessionStorage.getItem('id');
        }
    },
    mounted() {
        this.initJSPlumb();
    },
    beforeDestroy() {
        this.jsPlumb.deleteEveryConnection();
        this.jsPlumb.deleteEveryEndpoint();
    },
    methods: {
        async addTask() {
            this.jsPlumb.deleteEveryConnection();
            this.jsPlumb.deleteEveryEndpoint();
            const myId = this.$store.state.userInfo.id || window.sessionStorage.getItem('id');
            if (myId.toString() === this.user.id.toString()) {
                return this.$message.error('您不能给自己建任务');
            }
            if (!this.taskObj.taskName || !this.taskObj.taskDescription) {
                return this.$message.error('请完整填写任务表单');
            }
            const postRequest = {
                userId: this.user.id,
                chinaname: this.user.label,
                ...this.taskObj,
                createPerson: this.userName,
                createUserid: this.userId,
                money: Number(this.taskObj.money ?? 0)
            }
            const { status, message, data } = await setTask(postRequest);
            if (status !== 200) return this.$message.error(message);
            if (data.code === 401) return this.$message.error('您不是超级管理员，没有权限新建任务，请联系网站管理员');
            if (data.status && data.status === 401) {
                this.$message.error(data.message);
                return;
            }
            this.$message.success('新建成功');
            this.taskObj = {
                taskName: '',
                taskDescription: '',
                money: 0
            }
        },
        initJSPlumb() {
            this.jsPlumb = this.$jsPlumb.getInstance({
                Container: "efContainer", //容器id
                //  Endpoint:['Image', {
                //    src: 'static/images/def_endpoint.png',
                //  }] , // 端点的形状
                Anchor: [0.5, 1, 0, 1, -10, 50],
                Anchors: ["Right", "Left"], //连线的source和target 
                EndpointStyle: { radius: 6, fill: "#498FFF" }, //端点默认样式
                PaintStyle: { stroke: "#498FFF", strokeWidth: 1, fill: 'red' }, // 连线样式
                HoverPaintStyle: { stroke: "#818FB4" }, // 默认悬停样式
                // EndpointHoverStyle: {src: 'static/images/endpoint.png', },
                EndpointHoverStyle: { fill: "#818FB4" }, // 端点悬停样式
                ConnectionOverlays: [
                    [
                        "Arrow",
                        {
                            // 箭头
                            location: 1,
                            visible: false,
                            paintStyle: {
                                stroke: "#ccc",
                                fill: "#ccc"
                            }
                        }
                    ]
                ],
                Connector: ["Straight", { gap: 5 }], //要使用的默认连接器的类型：折线，流程等
                DrapOptions: { cursor: "crosshair", zIndex: 2000 },
                Scope: "jsPlumb_DefaultScope"
            });
        },
        connectTarget($event) {
            this.jsPlumb.deleteEveryConnection();
            this.jsPlumb.deleteEveryEndpoint();
            let id = '';
            id = $event.target.parentNode.previousElementSibling.id;
            if (!id) {
                id = $event.target.parentNode?.parentNode?.previousElementSibling?.id ?? '';
            }
            this.jsPlumb.connect({
                source: id,
                target: id + '-1'
            });
        }
    }
}

</script>

<style lang='less' scoped>
.task__container {
    margin: 12px 0;

    .task__main--grid {
        display: grid;
        grid-template-columns: 300px auto;
        gap: 24px;

        .custom__card--auto {
            min-height: 50vh;
            background: #ededed;

            li {
                background: #fff;
                width: 90%;
                margin: 12px auto;
                padding: 12px;
                border-radius: 3px;
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

                .item__title--pointer {
                    cursor: pointer;
                    margin-bottom: 8px;
                    font-size: 0.9rem;
                    font-weight: bold;
                    width: fit-content;
                    padding-right: 10px;

                    &::before {
                        content: '*';
                        color: #f40;
                        margin-right: 4px;
                    }
                }
            }
        }

        .task__code--highlight {
            min-height: 50vh;
            padding: 12px;
            border: 1px solid #e7e7e7;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            margin-right: 12px;
            background-color: #242424;
            font-weight: 600;

            .open__close--tag {
                color: rgb(255, 225, 0);
            }

            .ele__start {
                &:hover {
                    background: #e1fcff;
                }

                display: grid;
                grid-template-columns: min-content auto;
                flex-wrap: wrap;
                padding-left: 20px;
                color: rgb(0, 153, 255);

                .varable--orange {
                    color: #dbc16c;
                }
            }
        }
    }
}

span {
    word-break: normal;
    width: auto;
    display: block;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: hidden;
}

.warning-text {
    color: orange;
    font-size: 0.74rem;
    font-weight: bold;
    margin-top: 5px;
    cursor:pointer;
}
</style>